exports.getLogname = function (logType) {
    let logName = '';
    if (logType == 'depth') {
        logName = 'depthlog';
    } else if (logType == 'time') {
        logName = 'timelog';
    } else if (logType == 'trajectory') {
        logName = 'directionlog/trajectory';
    } else if (logType == 'trajectory-time') {
        logName = 'directionlog/time';
    }
    return logName;
}

exports.display = {

}

exports.screen = {

}

exports.background = {
    active: false,
    aspectRatio: false,
    class: "box-shaddow",
    displayId: '',
    draggable: true,
    isWidget: true,
    minh: 10,
    minw: 10,
    name: "Background",
    parentLim: true,
    resizable: true,
    snapToGrid: false,
    titleDisplay: true,
    widgetId: ''
}

exports.widgets = {
    active: false,
    aspectRatio: false,
    class: "box-shaddow",
    displayId: '',
    draggable: true,
    isWidget: true,
    minh: 10,
    minw: 10,
    name: "Background",
    parentLim: true,
    resizable: true,
    snapToGrid: false,
    titleDisplay: true,
    widgetId: ''
}